export const clientTypes = [
  {
    name: "MR",
    value: "MR",
  },
  {
    name: "PPD",
    value: "PPD",
  },
  {
    name: "MR / PPD",
    value: "Both",
  },
];
export const payoutStatus = ["Paid", "Unpaid"];
export const clientPaymentTypes = [
  {
    value: "Apple Pay",
    name: "Apple Pay",
    logo: require("@/assets/images/apple-pay.png"),
    title: "email",
  },
  {
    value: "Cash App",
    name: "Cash App",
    logo: require("@/assets/images/cashapp.png"),
    title: "username",
  },
  {
    value: "Credit Card",
    name: "Credit Card",
    logo: require("@/assets/images/credit-card.png"),
    title: "hint",
  },
  {
    value: "Venmo",
    name: "Venmo",
    logo: require("@/assets/images/venmo.png"),
    title: "username",
  },
  {
    value: "Zelle",
    name: "Zelle",
    logo: require("@/assets/images/zelle.png"),
    title: "phone_number",
  },
];

export const documentType = {
  DL_SSN_BINDER: "ZBmLwL4RizuzbhSUYoeh",
};
