<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <div>
      <label class="body-small" v-if="label">{{ label }}</label>
      <v-text-field
        v-on="$listeners"
        v-model="input"
        :dense="dense"
        v-maska
        ref="input"
        :data-maska="maska"
        :label="inlineLabel"
        :class="label ? 'mt-1' : ''"
        v-bind="$attrs"
        :value="input"
        @keydown="$emit('keydown', $event)"
        @keydown.enter.prevent="$emit('enter')"
        outlined
        @click:append="$emit('append')"
        @click:clear="$emit('clear')"
        @input="$emit('input', input)"
        :error-messages="errors"
      >
        <template v-slot:append>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </div>
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
import { vMaska } from "maska";

export default {
  inheritAttrs: false,
  directives: { maska: vMaska },
  props: {
    inlineLabel: String,
    maska: String,
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>
