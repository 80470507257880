<template>
  <div style="max-width: 700px">
    <form-wrapper @submit="submit()" ref="form">
      <v-row class="mt-1">
        <v-col cols="12" md="6" class="py-0">
          <input-field
            rules="required"
            @input="$emit('input', form)"
            label="Title"
            v-model="form.fax_title"
          ></input-field>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <select-field
            @input="$emit('input', form)"
            :items="schedules"
            rules="schedule"
            label="Schedule"
            v-model="form.schedule"
          />
        </v-col>
        <template v-if="isSendFaxAdmin">
          <v-col cols="12" md="6" class="py-0">
            <input-field
              outlined
              dense
              label="To"
              v-model="form.to"
            ></input-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <input-field
              disabled
              label="From (Select Customer)"
              :value="customer.name"
            ></input-field>
          </v-col>
        </template>
        <v-col cols="12" md="6" class="py-0">
          <select-field
            item-text="template_name"
            label="Template"
            disabled
            :items="templates"
            :value="value.template"
          />
        </v-col>
        <v-col cols="12" md="6" class="py-0" v-if="isSendFaxAdmin">
          <input-field
            label="From Number"
            :value="value.from_number"
            disabled
          ></input-field>
        </v-col>

        <!-- <v-col cols="12" class="py-0" v-if="isSendFaxAdmin">
          <ValidationProvider
            :rules="recipientRules"
            v-slot="{ errors }"
            mode="passive"
          >
            <v-combobox
              multiple
              small-chips
              :error-messages="errors"
              @change="onRecipientInput"
              dense
              deletable-chips
              outlined
              :items="recipients"
              v-model="form.recipients"
              label="Recipients"
            ></v-combobox>
          </ValidationProvider>
        </v-col> -->

        <v-col cols="12" class="py-0">
          <template-editor
            label="Message"
            v-model="form.cover_letter_message"
            @input="$emit('input', form)"
            minHeight="250px"
            v-if="isSendFaxAdmin"
          ></template-editor>
          <template v-else>
            <label>Cover Letter</label>
            <v-card
              flat
              width="100%"
              class="fax-preview pa-4 mb-4 grey lighten-2"
              v-html="formatMessage(form.cover_letter_message)"
            ></v-card>
          </template>
        </v-col>
      </v-row>
      <div class="subtitle-1 font-weight-bold">Documents you’ve selected</div>
      <div v-for="(item, i) in value.files" :key="i" style="max-width: 400px">
        <div class="ml-3">
          <div class="d-flex pa-2 align-center justify-space-between">
            <div>
              <v-icon>mdi-file</v-icon>
              <v-btn
                text
                @click="openFile(item)"
                :disabled="isDisabled(item)"
                class="subtitle-2 text-truncate pa-0 primary--text text-none"
              >
                {{ formatName(item.name) }}
              </v-btn>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </div>

      <v-card outlined class="dl-preview pa-4" v-if="dlDialog">
        <div class="d-flex">
          <div class="title">Preview of DL/SSN File</div>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="dlDialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-card-text class="pa-0">
          <iframe :src="`${dl.url}#navpanes=0`" style="object-fit: contain" />
        </v-card-text>
      </v-card>
      <div class="mt-3">
        <template v-if="isSendFaxAdmin">
          <v-btn
            :loading="loading"
            type="submit"
            depressed
            height="40px"
            color="success"
            class="mr-3"
            v-if="hasEdit('send-faxes') && !isManualPrint"
            >SEND</v-btn
          >
          <primary-button
            @click="submit('print')"
            class="mr-3"
            v-if="hasEdit('send-faxes')"
            :loading="loading && printType == 'print'"
            :disabled="loading && printType != 'print'"
            >Print</primary-button
          >
          <primary-button
            @click="submit('EFX')"
            class="mr-3"
            v-if="hasEdit('send-faxes')"
            :loading="loading && printType == 'EFX'"
            :disabled="loading && printType != 'EFX'"
          >
            Upload EFX
          </primary-button>

          <primary-button
            @click="submit('TU')"
            class="mr-3"
            v-if="hasEdit('send-faxes')"
            :loading="loading && printType == 'TU'"
            :disabled="loading && printType != 'TU'"
          >
            Upload TU
          </primary-button>
          <primary-button
            @click="submit('EXP')"
            class="mr-3"
            v-if="hasEdit('send-faxes')"
            :loading="loading && printType == 'EXP'"
            :disabled="loading && printType != 'EXP'"
          >
            Upload EXP
          </primary-button>
        </template>

        <v-btn
          outlined
          height="40px"
          color="red"
          :disabled="loading"
          @click="$emit('back')"
          >Back</v-btn
        >
        <v-btn
          outlined
          height="40px"
          color="red"
          class="ml-3"
          :disabled="loading"
          @click="close()"
          >Close</v-btn
        >
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import TemplateEditor from "@/components/ui/TemplateEditor.vue";
import rulesConstants from "@/assets/constants/rules.constants";
import {
  formatFax,
  formatPhoneNumber,
  formatUserLetter,
  getDate,
  isDLFile,
} from "@/assets/utils/common.utils";
import InputField from "@/components/ui/form/InputField.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import { httpsCallable } from "firebase/functions";
import { colFaxes, functions } from "@/assets/utils/firebase.utils";
import { doc, updateDoc } from "firebase/firestore";
export default {
  components: {
    TemplateEditor,
    InputField,
    SelectField,
    PrimaryButton,
    FormWrapper,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rules: [rulesConstants.required],
      recipientRules: "select",
      dlDialog: false,
      dl: {},
      form: {
        fax_title: "",
        schedule: null,
        recipients: [],
        cover_letter_message: "",
        to: "",
      },
      printType: "",
      loading: false,
    };
  },
  inject: [
    "recipients",
    "schedules",
    "templates",
    "customer",
    "fax",
    "faxId",
    "faxSettings",
    "prevRoute",
    "clientId",
  ],
  computed: {
    isManualPrint() {
      return this.faxSettings.schedule_action == "manual-print-draft";
    },
    message() {
      let customer = this.customer || {};
      let account_numbers = "";
      let accounts = this.value.accounts || [];
      accounts.forEach((i) => {
        account_numbers += `${i.name} ${i.account_number}<br/>`;
      });
      return formatFax({
        message: this.value.cover_letter,
        fields: { ...customer, account_numbers },
      });
    },
  },
  methods: {
    isDisabled({ name }) {
      if (this.isSendFaxAdmin) return false;
      return !isDLFile({ name });
    },
    formatMessage(message) {
      return formatUserLetter(message);
    },
    onRecipientInput() {
      let recipients = this.form.recipients.filter((i) => {
        i = i.match(/\d/g).join("");
        if (i.toString().length == 10) return true;
        return false;
      });
      recipients = recipients.map((i) => {
        i = i.match(/\d/g).join("");
        return formatPhoneNumber(i);
      });

      let notAllowed = this.form.recipients.filter((i) => {
        i = i.match(/\d/g).join("");
        if (i.toString().length != 10) return true;
        return false;
      });

      if (notAllowed.length != 0) {
        this.notifyError("Please enter a valid US Fax number.");
      }
      this.form.recipients = [];
      this.form.recipients = recipients;
      this.$emit("input", this.form);
    },
    openFile({ url }) {
      if (url instanceof File == false) {
        window.open(url, "_blank");
      }
    },
    formatName(name) {
      name = name.split(".")[0];
      if (name.length > 34) {
        return name.substr(0, 35) + "...";
      }
      return name;
    },
    openDL() {
      const vm = this;
      let dl = (vm.value.files || []).find((i) => isDLFile(i));
      if (dl) {
        vm.dl = dl;
        vm.dlDialog = true;
      }
    },
    async submit(type) {
      try {
        this.printType = type;
        this.loading = true;
        let is_print = ["print", "EFX", "TU", "EXP"].indexOf(type) != -1;
        if (is_print) {
          this.recipientRules = "";
          let isValid = await this.$refs.form.validate();
          if (!isValid) {
            this.loading = false;
            return;
          }
          setTimeout(() => {
            this.recipientRules = "select";
          }, 1000);
        }
        await updateDoc(doc(colFaxes, this.faxId), {
          ...this.form,
          updated_at: getDate(),
          last_step: 3,
        });
        let sendFax = httpsCallable(functions, "sendEfaxAction");
        let reqDoc = { id: this.faxId, is_print, type };
        let { status, message } = (await sendFax(reqDoc)).data;
        this.loading = false;
        if (!status) {
          if (this.isSendFaxAdmin) {
            this.notifyError(message);
          }
        } else {
          if (this.isSendFaxAdmin) {
            let isFax = this.fax.type == 1;
            let message = `${
              isFax ? "Fax" : "Letter"
            } has been submitted to send successfully.`;
            this.notifySuccess(message);
          }
        }
        this.close();
      } catch (error) {
        this.handleError(error);
      }
    },
    close() {
      if (this.prevRoute != null) {
        let client = this.prevRoute.matched.find(
          (i) => i.name == "client-dashboard"
        );
        if (client) {
          this.$router.replace({
            name: "client-dispute-letters",
            params: {
              id: this.clientId,
            },
          });
        } else {
          this.$router.replace("/dispute-letters");
        }
      } else {
        this.$router.replace("/dispute-letters");
      }
    },
  },
  mounted() {
    Object.keys(this.form).forEach((key) => {
      if (this.value[key] != undefined) {
        this.form[key] = this.value[key];
      }
    });
    this.openDL();
  },
};
</script>

<style lang="scss">
.dl-preview {
  width: 600px;
  height: calc(100vh - 210px);
  background: red;
  position: fixed;
  right: 20px;
  top: 120px;
  bottom: 0;
  margin: auto 0;
  iframe {
    border: none;
    width: calc(600px - 30px);
    height: calc(100vh - 280px);
  }
}
</style>
